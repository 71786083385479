import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Avatar,
    Button,
    Box,
    Chip,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    makeStyles,
    Slide,
    Fade,
    Paper,
} from '@material-ui/core';
import {
    Check,
    ExpandMore,
    Facebook,
    Twitter,
    LinkedIn,
    Instagram,
} from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f4f6f8',
        animation: `$fadeIn 1.5s ease-in-out`,
    },
    '@keyframes fadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
    },
    heroSection: {
        borderRadius: '50px',
        background: 'linear-gradient(90deg, rgba(242,246,252,1) 29%, rgba(219,219,219,1) 92%, rgba(255,255,255,1) 100%)',
        color: '#03115d',
        padding: theme.spacing(12, 0),
        position: 'relative',
        animation: `$fadeIn 2s ease-in-out`,
    },
    heroContent: {
        position: 'relative',
        zIndex: 1,
    },
    section: {
        padding: theme.spacing(8, 0),
        animation: `$slideIn 1s ease-in-out`,
    },
    formPaper: {
        padding: theme.spacing(4),
        backgroundColor: 'white',
        borderRadius: theme.spacing(2),
        boxShadow: theme.shadows[5],
        maxWidth: '400px',
        marginLeft: 'auto',
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    formButton: {
        marginTop: theme.spacing(2),
    },
    card: {
        height: '320px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
    },
    cardContent: {
        flexGrow: 1,
    },
    testimonial: {
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
    },
    statsCard: {
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
    },
}));

// The rest of your component remains the same



function LandingPage() {
    const classes = useStyles();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });
    const navigate = useNavigate()

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, such as sending the data to an API or email
        console.log('Form submitted:', formData);
    };

    const courses = [
        {
            id: 1,
            name: 'HTML & CSS Fundamentals',
            level: 'Beginner',
            img: 'https://logowik.com/content/uploads/images/css3-html51661.jpg',
            duration: '8 Weeks',
            curriculum: [
                { title: 'Introduction to HTML & CSS', description: 'Learn the basics of web development with HTML and CSS.' },
                { title: 'Advanced CSS', description: 'Learn advanced CSS techniques, including Flexbox and Grid layout.' },
            ],
            requirements: [
                'No prior experience required',
                'A computer with internet access',
                'Willingness to learn',
            ],
            features: [
                'Industry-recognized certifications',
                'Real-world projects',
                'Expert instructors',
            ],
            includes: [
                '20 hours of video content',
                '5 downloadable resources',
                '15 coding exercises',
                'Access on mobile and TV',
                'Certificate of completion',
            ],
            price: 'Free'
        },
        {
            id: 2,
            name: 'JavaScript Mastery',
            level: 'Intermediate',
            img: 'js.png',
            duration: '10 Weeks',
            curriculum: [
                { title: 'JavaScript Basics', description: 'Learn the fundamentals of JavaScript programming.' },
                { title: 'ES6 & Beyond', description: 'Dive into modern JavaScript features introduced in ES6 and later.' },
            ],
            requirements: [
                'Basic understanding of HTML & CSS',
                'A computer with internet access',
                'Willingness to learn',
            ],
            features: [
                'Industry-recognized certifications',
                'Real-world projects',
                'Expert instructors',
            ],
            includes: [
                '30 hours of video content',
                '8 downloadable resources',
                '20 coding exercises',
                'Access on mobile and TV',
                'Certificate of completion',
            ],
            price: '$199'
        },
        {
            id: 3,
            name: 'React & Redux',
            level: 'Advanced',
            img: 'redux.jpeg',
            duration: '12 Weeks',
            curriculum: [
                { title: 'React Basics', description: 'Understand the fundamentals of React and how to create reusable components.' },
                { title: 'State Management with Redux', description: 'Learn how to manage state in large applications using Redux.' },
            ],
            requirements: [
                'Understanding of JavaScript',
                'A computer with internet access',
                'Willingness to learn',
            ],
            features: [
                'Industry-recognized certifications',
                'Real-world projects',
                'Expert instructors',
            ],
            includes: [
                '40 hours of video content',
                '10 downloadable resources',
                '25 coding exercises',
                'Access on mobile and TV',
                'Certificate of completion',
            ],
            price: '$299'
        },
        {
            id: 4,
            name: 'Full Stack Development',
            level: 'Advanced',
            img: 'mern.jpg',
            duration: '16 Weeks',
            curriculum: [
                { title: 'Frontend Development', description: 'Learn to build user interfaces using React and other frontend technologies.' },
                { title: 'Backend Development', description: 'Master backend development using Node.js and Express.' },
                { title: 'Database Management', description: 'Learn to work with databases like MongoDB and MySQL.' },
            ],
            requirements: [
                'Understanding of HTML, CSS, and JavaScript',
                'A computer with internet access',
                'Willingness to learn',
            ],
            features: [
                'Industry-recognized certifications',
                'Real-world projects',
                'Expert instructors',
            ],
            includes: [
                '60 hours of video content',
                '15 downloadable resources',
                '35 coding exercises',
                'Access on mobile and TV',
                'Certificate of completion',
            ],
            price: '$499'
        },
    ];
    

    const features = [
        'Industry-recognized certifications',
        'Real-world projects',
        'Expert instructors',
        'Flexible learning schedule',
        '24/7 Support',
        'Job placement assistance',
    ];

    const testimonials = [
        { name: 'John Doe', role: 'Front-end Developer', content: 'This course transformed my career. I went from a beginner to landing my dream job in just 6 months!' },
        { name: 'Jane Smith', role: 'Full Stack Developer', content: 'The instructors are top-notch, and the curriculum is always up-to-date with the latest industry trends.' },
    ];

    const faqs = [
        { question: 'How long does it take to complete a course?', answer: 'Course duration varies, but most can be completed in 8-12 weeks with dedicated study.' },
        { question: 'Is there a money-back guarantee?', answer: 'Yes, we offer a 30-day money-back guarantee if you\'re not satisfied with the course.' },
    ];

    return (
        <div className={classes.root}>
            <Box className={classes.heroSection}>
                <Container maxWidth="lg" className={classes.heroContent}>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Fade in timeout={2000}>
                                <Typography component="h1" variant="h2" gutterBottom>
                                    Master Web Development
                                </Typography>
                            </Fade>
                            <Fade in timeout={2500}>
                                <Typography variant="h5" paragraph>
                                    Become a Full-Stack Developer in 6 Months
                                </Typography>
                            </Fade>
                            <Fade in timeout={3000}>
                                <Button variant="contained" color="secondary" size="large" style={{
                                    color: '#03115d',
                                    backgroundColor: '#ffffff',
                                    fontWeight: 'bold'
                                }} >
                                    Start Learning Now
                                </Button>
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={6} alignContent='center' alignItems='center' justifyContent='center'>
                            <Slide in direction="right" timeout={2000}>
                                <Paper className={classes.formPaper}>
                                    <Typography variant="h6" gutterBottom align='center'>
                                        Request a Callback
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <TextField
                                            className={classes.formField}
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                        <TextField
                                            className={classes.formField}
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                        <TextField
                                            className={classes.formField}
                                            label="Phone"
                                            variant="outlined"
                                            fullWidth
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                        <Button
                                            className={classes.formButton}
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            color='white'
                                            style={{ background: '#03115d', color: 'white' }}
                                        >
                                            Submit
                                        </Button>
                                    </form>
                                </Paper>
                            </Slide>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Container maxWidth="lg">
                <Box className={classes.section}>
                    <Typography variant="h4" gutterBottom align="center">
                        Our Courses
                    </Typography>
                    <Grid container spacing={4}>
                        {courses.map((course) => (
                            <Grid item key={course.name} xs={12} sm={6} md={3} >
                                <Card className={classes.card} style={{ height: '320px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <img src={course.img} alt="" style={{ height: '120px', objectFit: 'contain' }} />
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {course.name}
                                        </Typography>
                                        <Chip label={course.level} style={{ background: '#03115d' }} color="primary" />
                                    </CardContent>
                                    <CardContent>
                                        <Button variant="outlined" color="primary" onClick={()=>navigate(`course/${course.name}`)}>
                                            Learn More
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>

                <Box className={classes.section}>
                    <Card className={classes.statsCard}>
                        <CardContent>
                            <Typography variant="h4" gutterBottom align="center">
                                Why Choose Us
                            </Typography>
                            <Grid container spacing={4}>
                                <Grid item xs={12} md={6}>
                                    <List>
                                        {features.map((feature) => (
                                            <ListItem key={feature}>
                                                <ListItemIcon>
                                                    <Check color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={feature} />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                                <Grid item xs={12} md={6} alignItems='center' alignContent='center'>
                                    <Box textAlign="center">
                                        <Typography variant="h4" gutterBottom>
                                            100% Job Assistance
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            Our graduates get hired within 3 months of course completion
                                        </Typography>
                                        <Button variant="contained" color="primary" className={classes.button}>
                                            Learn More
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>


                <Box className={classes.section}>
                    <Typography variant="h4" gutterBottom align="center">
                        Success Stories
                    </Typography>
                    <Grid container spacing={4}>
                        {testimonials.map((testimonial) => (
                            <Grid item key={testimonial.name} xs={12} sm={6}>
                                <Card className={classes.testimonial}>
                                    <CardContent>
                                        <Avatar className={classes.avatar} src={`https://i.pravatar.cc/150?u=${testimonial.name}`} />
                                        <Typography gutterBottom variant="h6">
                                            {testimonial.name}
                                        </Typography>
                                        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                                            {testimonial.role}
                                        </Typography>
                                        <Typography variant="body2">
                                            "{testimonial.content}"
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Card className={classes.statsCard}>
                    <CardContent>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.stat}>
                                    <Typography variant="h4" className={classes.value}>
                                        1000+
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Students Enrolled
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.stat}>
                                    <Typography variant="h4" className={classes.value}>
                                        500+
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Successful Alumni
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box className={classes.stat}>
                                    <Typography variant="h4" className={classes.value}>
                                        90%
                                    </Typography>
                                    <Typography variant="subtitle1" className={classes.label}>
                                        Student Satisfaction
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Box className={classes.section}>
                    <Typography variant="h4" gutterBottom align="center">
                        Frequently Asked Questions
                    </Typography>
                    {faqs.map((faq, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>

                <Box className={classes.section} textAlign="center">
                    <Typography variant="h6" gutterBottom>
                        Follow Us
                    </Typography>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item>
                            <Button color="primary" startIcon={<Facebook />}>
                                Facebook
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" startIcon={<Twitter />}>
                                Twitter
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" startIcon={<LinkedIn />}>
                                LinkedIn
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button color="primary" startIcon={<Instagram />}>
                                Instagram
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    );
}

export default LandingPage;
