import React from 'react';
import { 
  BottomNavigation, 
  BottomNavigationAction 
} from '@material-ui/core';
import { Home, Chat, Search, LibraryBooks, Settings } from '@material-ui/icons';
import { useHistory, useNavigate } from 'react-router-dom';

function BottomNav() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      style={{position: 'fixed', bottom: 0, width: '100%'}}
    >
      <BottomNavigationAction label="Home" icon={<Home />} onClick={() => navigate('/')} />
      <BottomNavigationAction label="Chat" icon={<Chat />} onClick={() => navigate('/chat')} />
      <BottomNavigationAction label="Search" icon={<Search />} onClick={() => navigate('/search')} />
      <BottomNavigationAction label="Courses" icon={<LibraryBooks />} onClick={() => navigate('/courses')} />
      <BottomNavigationAction label="Settings" icon={<Settings />} onClick={() => navigate('/settings')} />
    </BottomNavigation>
  );
}

export default BottomNav;