import React from 'react';
import { BrowserRouter as Router, Route, Switch, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import BottomNav from './pages/BottomNav/BottomNav';
import Footer from './pages/Footer/Footer';
import CourseDetailPage from './pages/CaourseDetails/CourseDetails';

function App() {
  return (
      <Router>
        <div className="App" style={{paddingBottom: 56}}>
          <Routes>
          <Route exact path="/" element={<LandingPage/>} />
          <Route exact path="/course/:id" element={<CourseDetailPage/>} />
            {/* Add more routes here as you develop more pages */}
          </Routes>
          <Footer/>
          <BottomNav />
        </div>
      </Router>
  );
}

export default App;