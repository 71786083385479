import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  TextField,
  Button,
  makeStyles
} from '@material-ui/core';
import {
  Facebook,
  Twitter,
  Instagram,
  LinkedIn,
  YouTube,
  Phone,
  Email,
  LocationOn
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#1c2331',
    color: 'white',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  link: {
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      color: 'white',
    },
  },
  socialIcon: {
    color: 'white',
    marginRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  contactIcon: {
    marginRight: theme.spacing(1),
  },
  newsletterForm: {
    display: 'flex',
    marginTop: theme.spacing(2),
  },
  newsletterInput: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
    '& .MuiInputBase-input': {
      color: 'white',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'rgba(255, 255, 255, 0.7)',
    },
  },
  copyrightText: {
    marginTop: theme.spacing(4),
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.7)',
  },
}));

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.sectionTitle}>
              About WebDev Academy
            </Typography>
            <Typography variant="body2">
              We are dedicated to providing high-quality web development education to aspiring developers worldwide. Our courses are designed to help you succeed in the ever-evolving tech industry.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Quick Links
            </Typography>
            <Box display="flex" flexDirection="column">
              {['Home', 'Courses', 'Instructors', 'Blog', 'FAQ', 'Contact Us'].map((item) => (
                <Link key={item} href="#" className={classes.link}>
                  {item}
                </Link>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Contact Us
            </Typography>
            <Box className={classes.contactItem}>
              <Phone className={classes.contactIcon} />
              <Typography variant="body2">+1 (123) 456-7890</Typography>
            </Box>
            <Box className={classes.contactItem}>
              <Email className={classes.contactIcon} />
              <Typography variant="body2">info@webdevacademy.com</Typography>
            </Box>
            <Box className={classes.contactItem}>
              <LocationOn className={classes.contactIcon} />
              <Typography variant="body2">123 Web Street, Codeville, TC 12345</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.sectionTitle}>
              Newsletter
            </Typography>
            <Typography variant="body2">
              Subscribe to our newsletter for the latest updates and offers.
            </Typography>
            <form className={classes.newsletterForm}>
              <TextField
                className={classes.newsletterInput}
                placeholder="Your email"
                variant="standard"
              />
              <Button variant="contained" color="primary">
                Subscribe
              </Button>
            </form>
          </Grid>
        </Grid>
        <Box mt={4}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Follow Us
          </Typography>
          <IconButton className={classes.socialIcon} aria-label="Facebook">
            <Facebook />
          </IconButton>
          <IconButton className={classes.socialIcon} aria-label="Twitter">
            <Twitter />
          </IconButton>
          <IconButton className={classes.socialIcon} aria-label="Instagram">
            <Instagram />
          </IconButton>
          <IconButton className={classes.socialIcon} aria-label="LinkedIn">
            <LinkedIn />
          </IconButton>
          <IconButton className={classes.socialIcon} aria-label="YouTube">
            <YouTube />
          </IconButton>
        </Box>
        <Typography variant="body2" className={classes.copyrightText}>
          © {currentYear} WebDev Academy. All rights reserved.
        </Typography>
      </Container>
    </footer>
  );
}

export default Footer;