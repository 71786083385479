import React, { useState } from 'react';
import {
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Box,
    makeStyles,
    TextField
} from '@material-ui/core';
import { Check, ExpandMore } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#f4f6f8',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    section: {
        padding: theme.spacing(4, 0),
    },
    card: {
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
        },
        marginBottom: theme.spacing(4),
    },
    cardContent: {
        flexGrow: 1,
    },
    button: {
        marginTop: theme.spacing(4),
    },
    formField: {
        marginBottom: theme.spacing(2),
    },
    caption: {
        marginTop: theme.spacing(2),
        fontStyle: 'italic',
        color: theme.palette.text.secondary,
    },
    price: {
        marginTop: theme.spacing(2),
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    }
}));

function CourseDetail() {
    const classes = useStyles();
    const [hasEnrolled, setHasEnrolled] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Handle form submission (e.g., API call)
        console.log('Form Submitted:', formData);
        setHasEnrolled(true);
    };

    const curriculum = [
        { title: 'Introduction to HTML & CSS', description: 'Learn the basics of web development with HTML and CSS.' },
        { title: 'JavaScript Essentials', description: 'Master the fundamentals of JavaScript.' },
        { title: 'Advanced React', description: 'Dive deep into React and learn how to manage state with Redux.' },
    ];

    const features = [
        'Files & Resources',
        'Articles',
        'Assignments',
        'Coding Exercises',
        'Lifetime Access',
        'Certification',
    ];

    const requirements = [
        'Basic understanding of HTML & CSS',
        'A computer with internet access',
        'Willingness to learn',
    ];

    const courseIncludes = [
        '30 hours of video content',
        '10 downloadable resources',
        '30 coding exercises',
        'Access on mobile and TV',
        'Certificate of completion',
    ];

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <Typography variant="h3" gutterBottom align="center">
                    Course Title
                </Typography>
                <Typography variant="h6" paragraph align="center">
                    Learn everything you need to become a full-stack web developer.
                </Typography>

                <Box className={classes.section} textAlign="center">
                    <Typography variant="h4" gutterBottom>
                        7 Days Free Course
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Start your learning journey with our 7-day free course. No credit card required.
                    </Typography>
                    <form onSubmit={handleFormSubmit}>
                        <TextField
                            className={classes.formField}
                            label="Name"
                            variant="outlined"
                            fullWidth
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            className={classes.formField}
                            label="Email"
                            variant="outlined"
                            fullWidth
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            className={classes.button}
                        >
                            Enroll for Free
                        </Button>
                    </form>
                </Box>

                <Box className={classes.section}>
                    <Typography variant="h4" gutterBottom>
                        Curriculum
                    </Typography>
                    {curriculum.map((item, index) => (
                        <Accordion key={index}>
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                <Typography variant="h6">{item.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{item.description}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>

                <Box className={classes.section}>
                    <Typography variant="h4" gutterBottom>
                        Course Duration
                    </Typography>
                    <Typography variant="h6">
                        12 Weeks
                    </Typography>
                </Box>

                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            Requirements
                        </Typography>
                        <List>
                            {requirements.map((requirement, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <Check color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={requirement} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>

                <Card className={classes.card}>
                    <CardContent>
                        <Typography variant="h4" gutterBottom>
                            This Course Includes
                        </Typography>
                        <List>
                            {courseIncludes.map((include, index) => (
                                <ListItem key={index}>
                                    <ListItemIcon>
                                        <Check color="primary" />
                                    </ListItemIcon>
                                    <ListItemText primary={include} />
                                </ListItem>
                            ))}
                        </List>
                    </CardContent>
                </Card>

                <Box className={classes.section} textAlign="center">
                    <Typography variant="h5" className={classes.price}>
                        Paid Course: $299
                    </Typography>
                    <Typography variant="body1" className={classes.caption}>
                        Live classes will be taken on Google Meet, and you will get access to the student portal.
                    </Typography>
                    <Button variant="contained" color="primary" className={classes.button} size="large">
                        Enroll in Paid Course
                    </Button>
                </Box>
            </Container>
        </div>
    );
}

export default CourseDetail;
